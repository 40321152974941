const isEndOfTag = function (char) {
  return char === ">";
};
const isStartOfTag = function (char) {
  return char === "<";
};
const isWhitespace = function (char) {
  return /^\s+$/.test(char);
};
const isTag = function (token) {
  return /^\s*<[^>]+>\s*$/.test(token);
};
const isntTag = function (token) {
  return !isTag(token);
};
const Match = class Match {
  constructor(startInBefore1, startInAfter1, length1) {
    this.start_in_before = startInBefore1;
    this.start_in_after = startInAfter1;
    this.length = length1;
    this.end_in_before = this.start_in_before + this.length - 1;
    this.end_in_after = this.start_in_after + this.length - 1;
  }
};
const htmlToTokens = function (html) {
  let char, currentWord, i, len, mode;
  mode = "char";
  currentWord = "";
  const words = [];
  for (i = 0, len = html.length; i < len; i++) {
    char = html[i];
    switch (mode) {
      case "tag":
        if (isEndOfTag(char)) {
          currentWord += ">";
          words.push(currentWord);
          currentWord = "";
          if (isWhitespace(char)) {
            mode = "whitespace";
          } else {
            mode = "char";
          }
        } else {
          currentWord += char;
        }
        break;
      case "char":
        if (isStartOfTag(char)) {
          if (currentWord) {
            words.push(currentWord);
          }
          currentWord = "<";
          mode = "tag";
        } else if (/\s/.test(char)) {
          if (currentWord) {
            words.push(currentWord);
          }
          currentWord = char;
          mode = "whitespace";
        } else if (/[\w\#@]+/i.test(char)) {
          currentWord += char;
        } else {
          if (currentWord) {
            words.push(currentWord);
          }
          currentWord = char;
        }
        break;
      case "whitespace":
        if (isStartOfTag(char)) {
          if (currentWord) {
            words.push(currentWord);
          }
          currentWord = "<";
          mode = "tag";
        } else if (isWhitespace(char)) {
          currentWord += char;
        } else {
          if (currentWord) {
            words.push(currentWord);
          }
          currentWord = char;
          mode = "char";
        }
        break;
      default:
        throw new Error(`Unknown mode ${mode}`);
    }
  }
  if (currentWord) {
    words.push(currentWord);
  }
  return words;
};
const findMatch = function (
  beforeTokens,
  afterTokens,
  indexOfBeforeLocationsInAfterTokens,
  startInBefore,
  endInBefore,
  startInAfter,
  endInAfter
) {
  let bestMatchInAfter,
    bestMatchInBefore,
    bestMatchLength,
    i,
    indexInAfter,
    indexInBefore,
    j,
    len,
    locationsInAfter,
    lookingFor,
    match,
    matchLengthAt,
    newMatchLength,
    newMatchLengthAt,
    ref,
    ref1;
  bestMatchInBefore = startInBefore;
  bestMatchInAfter = startInAfter;
  bestMatchLength = 0;
  matchLengthAt = {};
  for (
    indexInBefore = i = ref = startInBefore, ref1 = endInBefore;
    ref <= ref1 ? i < ref1 : i > ref1;
    indexInBefore = ref <= ref1 ? ++i : --i
  ) {
    newMatchLengthAt = {};
    lookingFor = beforeTokens[indexInBefore];
    locationsInAfter = indexOfBeforeLocationsInAfterTokens[lookingFor];
    for (j = 0, len = locationsInAfter.length; j < len; j++) {
      indexInAfter = locationsInAfter[j];
      if (indexInAfter < startInAfter) {
        continue;
      }
      if (indexInAfter >= endInAfter) {
        break;
      }
      if (matchLengthAt[indexInAfter - 1] == null) {
        matchLengthAt[indexInAfter - 1] = 0;
      }
      newMatchLength = matchLengthAt[indexInAfter - 1] + 1;
      newMatchLengthAt[indexInAfter] = newMatchLength;
      if (newMatchLength > bestMatchLength) {
        bestMatchInBefore = indexInBefore - newMatchLength + 1;
        bestMatchInAfter = indexInAfter - newMatchLength + 1;
        bestMatchLength = newMatchLength;
      }
    }
    matchLengthAt = newMatchLengthAt;
  }
  if (bestMatchLength !== 0) {
    match = new Match(
      bestMatchInBefore,
      bestMatchInAfter,
      bestMatchLength
    );
  }
  return match;
};
const recursivelyFindMatchingBlocks = function (
  beforeTokens,
  afterTokens,
  indexOfBeforeLocationsInAfterTokens,
  startInBefore,
  endInBefore,
  startInAfter,
  endInAfter,
  matchingBlocks
) {
  const match = findMatch(
    beforeTokens,
    afterTokens,
    indexOfBeforeLocationsInAfterTokens,
    startInBefore,
    endInBefore,
    startInAfter,
    endInAfter
  );
  if (match != null) {
    if (
      startInBefore < match.start_in_before &&
      startInAfter < match.start_in_after
    ) {
      recursivelyFindMatchingBlocks(
        beforeTokens,
        afterTokens,
        indexOfBeforeLocationsInAfterTokens,
        startInBefore,
        match.start_in_before,
        startInAfter,
        match.start_in_after,
        matchingBlocks
      );
    }
    matchingBlocks.push(match);
    if (
      match.end_in_before <= endInBefore &&
      match.end_in_after <= endInAfter
    ) {
      recursivelyFindMatchingBlocks(
        beforeTokens,
        afterTokens,
        indexOfBeforeLocationsInAfterTokens,
        match.end_in_before + 1,
        endInBefore,
        match.end_in_after + 1,
        endInAfter,
        matchingBlocks
      );
    }
  }
  return matchingBlocks;
};
const createIndex = function (p) {
  let i, idx, len, token;
  if (p.find_these == null) {
    throw new Error("params must have find_these key");
  }
  if (p.in_these == null) {
    throw new Error("params must have in_these key");
  }
  const index = {};
  const ref = p.find_these;
  for (i = 0, len = ref.length; i < len; i++) {
    token = ref[i];
    index[token] = [];
    idx = p.in_these.indexOf(token);
    while (idx !== -1) {
      index[token].push(idx);
      idx = p.in_these.indexOf(token, idx + 1);
    }
  }
  return index;
};
const findMatchingBlocks = function (beforeTokens, afterTokens) {
  const matchingBlocks = [];
  const indexOfBeforeLocationsInAfterTokens = createIndex({
    find_these: beforeTokens,
    in_these: afterTokens
  });
  return recursivelyFindMatchingBlocks(
    beforeTokens,
    afterTokens,
    indexOfBeforeLocationsInAfterTokens,
    0,
    beforeTokens.length,
    0,
    afterTokens.length,
    matchingBlocks
  );
};
const calculateOperations = function (beforeTokens, afterTokens) {
  let actionUpToMatchPositions,
    i,
    index,
    j,
    lastOp,
    len,
    len1,
    match,
    matchStartsAtCurrentPositionInAfter,
    matchStartsAtCurrentPositionInBefore,
    op,
    positionInAfter,
    positionInBefore;
  if (beforeTokens == null) {
    throw new Error("beforeTokens?");
  }
  if (afterTokens == null) {
    throw new Error("afterTokens?");
  }
  positionInBefore = positionInAfter = 0;
  const operations = [];
  const actionMap = {
    "false,false": "replace",
    "true,false": "insert",
    "false,true": "delete",
    "true,true": "none"
  };
  const matches = findMatchingBlocks(beforeTokens, afterTokens);
  matches.push(new Match(beforeTokens.length, afterTokens.length, 0));
  for (index = i = 0, len = matches.length; i < len; index = ++i) {
    match = matches[index];
    matchStartsAtCurrentPositionInBefore =
      positionInBefore === match.start_in_before;
    matchStartsAtCurrentPositionInAfter =
      positionInAfter === match.start_in_after;
    actionUpToMatchPositions =
      actionMap[
        [
          matchStartsAtCurrentPositionInBefore,
          matchStartsAtCurrentPositionInAfter
        ].toString()
      ];
    if (actionUpToMatchPositions !== "none") {
      operations.push({
        action: actionUpToMatchPositions,
        start_in_before: positionInBefore,
        end_in_before:
          actionUpToMatchPositions !== "insert"
            ? match.start_in_before - 1
            : undefined,
        start_in_after: positionInAfter,
        end_in_after:
          actionUpToMatchPositions !== "delete"
            ? match.start_in_after - 1
            : undefined
      });
    }
    if (match.length !== 0) {
      operations.push({
        action: "equal",
        start_in_before: match.start_in_before,
        end_in_before: match.end_in_before,
        start_in_after: match.start_in_after,
        end_in_after: match.end_in_after
      });
    }
    positionInBefore = match.end_in_before + 1;
    positionInAfter = match.end_in_after + 1;
  }
  const postProcessed = [];
  lastOp = {
    action: "none"
  };
  const isSingleWhitespace = function (op) {
    if (op.action !== "equal") {
      return false;
    }
    if (op.end_in_before - op.start_in_before !== 0) {
      return false;
    }
    return /^\s$/.test(
      beforeTokens.slice(op.start_in_before, +op.end_in_before + 1 || 9e9)
    );
  };
  for (j = 0, len1 = operations.length; j < len1; j++) {
    op = operations[j];
    if (
      (isSingleWhitespace(op) && lastOp.action === "replace") ||
      (op.action === "replace" && lastOp.action === "replace")
    ) {
      lastOp.end_in_before = op.end_in_before;
      lastOp.end_in_after = op.end_in_after;
    } else {
      postProcessed.push(op);
      lastOp = op;
    }
  }
  return postProcessed;
};
const consecutiveWhere = function (start, content, predicate) {
  let answer, i, index, lastMatchingIndex, len, token;
  content = content.slice(start, +content.length + 1 || 9e9);
  lastMatchingIndex = undefined;
  for (index = i = 0, len = content.length; i < len; index = ++i) {
    token = content[index];
    answer = predicate(token);
    if (answer === true) {
      lastMatchingIndex = index;
    }
    if (answer === false) {
      break;
    }
  }
  if (lastMatchingIndex != null) {
    return content.slice(0, +lastMatchingIndex + 1 || 9e9);
  }
  return [];
};
const wrap = function (tag, content) {
  let nonTags, position, rendering, tags;
  rendering = "";
  position = 0;
  const length = content.length;
  while (true) {
    if (position >= length) {
      break;
    }
    nonTags = consecutiveWhere(position, content, isntTag);
    position += nonTags.length;
    if (nonTags.length !== 0) {
      rendering += `<${tag}>${nonTags.join("")}</${tag}>`;
    }
    if (position >= length) {
      break;
    }
    tags = consecutiveWhere(position, content, isTag);
    position += tags.length;
    rendering += tags.join("");
  }
  return rendering;
};
const opMap = {
  equal(op, beforeTokens, afterTokens) {
    return beforeTokens
      .slice(op.start_in_before, +op.end_in_before + 1 || 9e9)
      .join("");
  },
  insert(op, beforeTokens, afterTokens) {
    const val = afterTokens.slice(op.start_in_after, +op.end_in_after + 1 || 9e9);
    return wrap("ins", val);
  },
  delete(op, beforeTokens, afterTokens) {
    const val = beforeTokens.slice(op.start_in_before, +op.end_in_before + 1 || 9e9);
    return wrap("del", val);
  }
};
opMap.replace = function (op, beforeTokens, afterTokens) {
  return (
    opMap.insert(op, beforeTokens, afterTokens) +
    opMap.delete(op, beforeTokens, afterTokens)
  );
};
const renderOperations = function (beforeTokens, afterTokens, operations) {
  let i, len, op, rendering;
  rendering = "";
  for (i = 0, len = operations.length; i < len; i++) {
    op = operations[i];
    rendering += opMap[op.action](op, beforeTokens, afterTokens);
  }
  return rendering;
};
const diff = function (before, after) {
  if (before === after) {
    return before;
  }
  before = htmlToTokens(before);
  after = htmlToTokens(after);
  const ops = calculateOperations(before, after);
  return renderOperations(before, after, ops);
};
diff.html_to_tokens = htmlToTokens;
diff.find_matching_blocks = findMatchingBlocks;
findMatchingBlocks.find_match = findMatch;
findMatchingBlocks.create_index = createIndex;
diff.calculate_operations = calculateOperations;
diff.render_operations = renderOperations;

/* eslint-disable no-undef */
if (typeof define === "function") {
  define([], function () {
    return diff;
  });
} else if (typeof module !== "undefined" && module !== null) {
  module.exports = diff;
} else {
  this.htmldiff = diff;
}

// Invoked like so => let output = htmldiff(originalHTML, newHTML);
